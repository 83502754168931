import Google from "@/icons/Google";
import Star from "@/icons/Star";
import Image from "next/image";

export default function OpinionCard({ data }) {
  return (
    <div className="xs:flex-[0_0_70%] h-auto flex-[0_0_95%] sm:min-w-96 sm:flex-[0_0_45%] md:flex-[0_0_32.1%]">
      <div className="flex h-full w-full select-none gap-4 rounded-xl bg-neutral-200 p-4 sm:gap-5 sm:p-6">
        <div className="flex flex-col items-center gap-3">
          <div className="size-9 min-h-12 rounded-full sm:size-12">
            <Image alt="Cover image" src={`/avatars/avatar${data.id}.png`} width={800} height={800} />
          </div>
          <div className="h-full w-0.5 rounded-sm bg-neutral-300"></div>
          <Google className="size-4 min-h-5 sm:size-5" />
        </div>
        <div className="flex flex-col gap-4">
          <p className="line-clamp-6 h-full overflow-hidden text-sm text-neutral-600">{data.text}</p>
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">{data.name}</span>
            <div className="flex gap-1 pb-0.5 text-yellow-500">
              <Star className="h-4 w-4" />
              <Star className="h-4 w-4" />
              <Star className="h-4 w-4" />
              <Star className="h-4 w-4" />
              <Star className="h-4 w-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
