"use client";

import OpinionCard from "@/components/OpinionCard";
import { DotButton, useDotButton } from "@/utils/emblaDots";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { useCallback, useEffect, useState } from "react";
import Play from "@/icons/Play";
import Stop from "@/icons/Stop";
import data from "@/data/testimotials";

export default function Testimotials() {
  const [isPlaying, setIsPlaying] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      slidesToScroll: 1,
      breakpoints: { "(min-width: 858px)": { slidesToScroll: 2, align: "start" } },
    },
    [Autoplay()]
  );

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop = autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, onNavButtonClick);

  const toggleAutoplay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play;
    playOrStop();
  }, [emblaApi]);

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    setIsPlaying(autoplay.isPlaying());
    emblaApi
      .on("autoplay:play", () => setIsPlaying(true))
      .on("autoplay:stop", () => setIsPlaying(false))
      .on("reInit", () => setIsPlaying(autoplay.isPlaying()));
  }, [emblaApi]);

  return (
    <div className="flex flex-col items-center justify-center gap-12 py-9 sm:py-12" id="opinie">
      <div className="flex w-full max-w-7xl flex-col items-center gap-3 overflow-hidden px-4 sm:gap-5 sm:px-6">
        <span className="text-lg font-semibold text-red-600">Opinie</span>
        <h2 className="text-center text-2xl font-semibold sm:text-3xl">Co mówią o nas klienci</h2>
        <div className="mt-6 h-full w-full" ref={emblaRef}>
          <div className="flex h-full items-stretch gap-4 sm:gap-6">
            {data.map((e, i) => (
              <OpinionCard data={e} key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button
          className="-mr-1 flex h-5 w-5 items-center justify-center text-red-600"
          onClick={toggleAutoplay}
          type="button"
          aria-label={isPlaying ? "Zatrzymaj karuzele" : "Uruchom karuzele"}
        >
          {isPlaying ? <Play /> : <Stop />}
        </button>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={`h-3 w-3 rounded-full bg-neutral-200 ${index === selectedIndex ? "bg-red-600" : ""}`}
            aria-label={`Otwórz slajd numer ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}
